import React from 'react';
import PropTypes from 'prop-types';
import { Heading2 } from '../TextStyles';
import Spacer from '../Spacer';
import Container from '../_layouts/Container';

import RichText from '../RichText';
import SplitText from '../_animation/AnimateSplitText';

import {
  PrivacyPolicyTitle,
  PrivacyPolicyWrapper,
  PrivacyPolicyContent,
} from './index.style';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';

const ScienceIntro = ({ title, content }) => {
  return (
    <PrivacyPolicyWrapper>
      <Container>
        <Grid>
          <GridItem mobile={14} mobileStart={2} tabletL={8} tabletLStart={5}>
            <PrivacyPolicyTitle>
              <Heading2 as="h1">
                <SplitText type={`lines`} heading={true} delay={0.8}>
                  {title}
                </SplitText>
              </Heading2>
              <Spacer size={[18, 24]} />
            </PrivacyPolicyTitle>
            <PrivacyPolicyContent>
              <RichText content={content} delay={1} />
            </PrivacyPolicyContent>
          </GridItem>
        </Grid>
      </Container>
    </PrivacyPolicyWrapper>
  );
};

ScienceIntro.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default ScienceIntro;
