import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import ScrollSection from '../components/ScrollSection';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Spacer from '../components/Spacer';
import Footer from '../components/Footer';

const PrivacyPage = (props) => {
  const {
    metaDescription,
    title,
    content,
  } = props.data.allContentfulPagePrivacy.nodes[0];

  return (
    <>
      <SEO
        title="Privacy Policy"
        description={metaDescription.metaDescription}
        pathname={`privacy-policy`}
      />{' '}
      <Spacer size={[100, 250]} />
      <ScrollSection threshold={0}>
        <PrivacyPolicy title={title} content={content} />
      </ScrollSection>
      <Footer />
    </>
  );
};

export default PrivacyPage;

export const pageQuery = graphql`
  query PagePrivacy {
    allContentfulPagePrivacy {
      nodes {
        title
        metaDescription {
          metaDescription
        }
        content {
          raw
        }
      }
    }
  }
`;
