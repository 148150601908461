import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { mq } from '../../styles/vars/media-queries.style';
import { ButtonIconWrapper } from '../Button/index.style';

export const PrivacyPolicyWrapper = styled.div`
  ${clamp('padding-bottom', 80, 162)}
`;
export const PrivacyPolicyTitle = styled.div`
  margin-bottom: 4.1rem;

  ${clamp('margin-bottom', 28, 41)}
`;

export const PrivacyPolicyContent = styled.div`
  p {
    ${clamp('margin-bottom', 20, 40)}

    ${ButtonIconWrapper} {
      margin-top: 0;
      top: 0.7rem;
    }
  }

  div {
    a,
    span {
      display: inline-block;
    }
    strong {
      display: inline;
    }
  }

  ${mq.desk} {
    a,
    span {
      display: inline-block;
    }

    strong {
      display: inline;
    }
  }

  h5 {
    ${clamp('margin-bottom', 20, 40)}
  }

  li {
    padding-left: 2rem;
    position: relative;
    list-style-type: none;

    &:before {
      background: ${colors.humeBlack700};
      border-radius: 50%;
      content: '';
      height: 0.6rem;
      opacity: 0;
      position: absolute;
      left: 0;
      // top: 1.3rem;

      ${clamp('top', 12, 14)}
      width: 0.6rem;

      [data-inview='true'] & {
        opacity: 1;
        transition: opacity 0.3s ${easings.text.in} 2s;
      }

      ${mq.deskL} {
        // top: 1.7rem;
      }
    }
  }
`;
